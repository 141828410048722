import { UserType } from '@les-sherpas/sherpas-toolbox';
import posthog from 'posthog-js';

export const identifyStudentUser = ({
  email,
  userType,
  hasActiveSubscription,
}: {
  email: string;
  userType?: UserType;
  hasActiveSubscription?: boolean;
  hasAnnounceAtHome?: boolean;
  isCertified?: boolean;
  hasNovaAgreementNumber?: boolean;
}) => {
  posthog.identify(email, { email, userType, hasActiveSubscription });
};

export const identifyTeacherUser = ({
  email,
  userType,
  hasActiveSubscription,
  hasAnnounceAtHome,
  isCertified,
  hasNovaAgreementNumber,
}: {
  email: string;
  userType?: UserType;
  hasActiveSubscription?: boolean;
  hasAnnounceAtHome: boolean;
  isCertified: boolean;
  hasNovaAgreementNumber: boolean;
}) => {
  posthog.identify(email, {
    email,
    userType,
    hasActiveSubscription,
    hasAnnounceAtHome,
    isCertified,
    hasNovaAgreementNumber,
  });
};

export const resetUser = () => {
  posthog.reset();
};
