import {
  AuthenticatedTeacherInfos,
  stripeAccountSource,
} from '@les-sherpas/sherpas-toolbox';

class TeacherInfosClass {
  constructor(teacher?: AuthenticatedTeacherInfos) {
    if (teacher) {
      this.draftId = teacher.draftId;
      this.hasAnnounce = !!teacher.hasAnnounce;
      this.shouldBeOnboard = teacher.shouldBeOnboard;
      this.birthday = teacher.birthday;
      this.hasClickedOnTrainingAppointment =
        teacher.hasClickedOnTrainingAppointment;
      this.institution = teacher.institution;
      this.hasBookedOnTrainingAppointment =
        teacher.hasBookedOnTrainingAppointment;
      this.hasSherpaDomStripeAcct = !!teacher.userStripes?.find(
        (userStripe) =>
          userStripe.stripeAccountSource === stripeAccountSource.sherpasDom &&
          !!userStripe.stripeAccountId
      );
      this.hasNovaAgreementNumber = !!teacher.hasNovaAgreementNumber;
      this.hasAnnounceAtHome = !!teacher.hasAnnounceAtHome;
      this.isCertified = !!teacher.isCertified;
    }
  }

  public readonly draftId?: number;

  public readonly hasAnnounce: boolean;

  public readonly shouldBeOnboard?: boolean;

  public readonly birthday?: Date;

  public readonly hasClickedOnTrainingAppointment?: boolean;

  public readonly hasBookedOnTrainingAppointment?: boolean;

  public readonly institution?: string;

  public readonly hasSherpaDomStripeAcct?: boolean;

  get hasDraftInProgress(): boolean {
    return this.draftId > 0;
  }

  public readonly hasNovaAgreementNumber: boolean;

  public readonly hasAnnounceAtHome: boolean;

  public readonly isCertified: boolean;
}

export default TeacherInfosClass;
